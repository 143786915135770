import clsx from 'clsx';
import { get, isNil } from 'lodash-es';
import { useFormContext, useWatch } from 'react-hook-form';
import {
  Form,
  FormControlProps,
  Icon,
  Text,
  Tooltip,
  useConfiguredForm,
} from '../index';
import { FormFeedback, FormGroup, Label } from './common';
import './text-field.scss';
import { FormTranslateFn } from '@wyz/types';

type CommonProps = Omit<FormControlProps, 'translate'> & {
  name: string;
  label?: string;
  helperText?: string;
  required?: boolean;
  tooltips?: string;
  formGroupClassName?: string;
  cols?: number;
  rows?: number;
  maxLength?: number;
  feedbackType?: 'tooltip' | 'inline';
};
export type TextFieldProps = (
  | { type?: 'text' }
  | { type: 'color' }
  | { type: 'search' }
  | { type: 'password' }
  | { type: 'number'; min?: number; max?: number }
) &
  CommonProps;

type PrivateProps<TKeys extends string> = TextFieldProps & {
  translate: FormTranslateFn<TKeys>;
};

const TextField = <TKeys extends string>({
  label,
  type = 'text',
  helperText,
  tooltips,
  translate,
  formGroupClassName = 'mb-2',
  maxLength,
  feedbackType = 'inline',
  ...props
}: PrivateProps<TKeys>) => {
  const methods = useFormContext();
  const errorMessage = get(
    methods.formState.errors,
    `${props.name}.message`,
  ) as unknown as TKeys;
  const configurationContext = useConfiguredForm();
  const watchedValue = useWatch({ name: props.name });
  return (
    <FormGroup
      className={clsx(formGroupClassName, `input-${props.size || 'lg'}`)}
    >
      {label && (
        <div className='space-x-2 position-relative'>
          {tooltips && (
            <div className='position-absolute'>
              <Tooltip placement='right' label={'tooltips'}>
                <Icon icon={'info-circle'} width={13} height={13} />
              </Tooltip>
            </div>
          )}
          <Label
            htmlFor={props.id || props.name}
            className={clsx({
              'ms-3': tooltips,
              'required': props.required,
            })}
            style={{
              visibility: props.hidden ? 'hidden' : 'visible',
            }}
          >
            {label}
            {!isNil(maxLength) && (
              <span> {` (${watchedValue?.length || 0}/${maxLength})`}</span>
            )}
          </Label>
        </div>
      )}
      {/* workaround to add a divider with css */}
      <div id='input-container'>
        {configurationContext.mode === 'WRITE' ? (
          <Form.Control
            {...props}
            className={clsx(props.className, {
              'empty-color': !methods.getValues(props.name),
            })}
            id={props.id || props.name}
            type={type}
            isInvalid={!!errorMessage}
            {...methods.register(props.name, {
              setValueAs: (value) => {
                if (type === 'number') {
                  const maybeNumber =
                    typeof value === 'string' ? parseFloat(value) : value;
                  if (
                    Number.isFinite(maybeNumber) &&
                    !Number.isNaN(maybeNumber)
                  ) {
                    return maybeNumber;
                  }
                  return null;
                }
                return value;
              },
            })}
            data-cy={props.name}
          />
        ) : (
          <Text heading='normal' weight='semi-bold'>
            {type === 'color' ? (
              <>
                {methods.getValues(props.name) ? (
                  <span
                    className='d-block'
                    style={{
                      height: 10,
                      width: 50,
                      background: methods.getValues(props.name),
                      border: '1px solid',
                    }}
                  ></span>
                ) : (
                  '-'
                )}
              </>
            ) : (
              methods.getValues(props.name) || '-'
            )}
          </Text>
        )}
      </div>
      {errorMessage ? (
        <FormFeedback invalid tooltip={feedbackType === 'tooltip'}>
          {translate(errorMessage, { length: maxLength })}
        </FormFeedback>
      ) : helperText ? (
        <FormFeedback>{helperText}</FormFeedback>
      ) : null}
    </FormGroup>
  );
};

export default TextField;
