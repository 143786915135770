import i18n from 'i18next';
import HttpBackend, { HttpBackendOptions } from 'i18next-http-backend';

import { initReactI18next } from 'react-i18next';
import { log } from '~/common/helpers';
import { Domain } from '~/common/types';

const DEFAULT_LANGUAGE = 'fr';

export const init = () =>
  i18n
    .use(HttpBackend)
    .use(initReactI18next)
    .init<HttpBackendOptions>({
      lng: DEFAULT_LANGUAGE,
      fallbackLng: DEFAULT_LANGUAGE,
      // preload: true,
      partialBundledLanguages: true, // todo
      // ns: [],
      ns: ['settings', 'orders'] as Array<Domain>,
      resources: {},
      // detection: {
      //   order: ['path', 'cookie', 'localStorage', 'navigator', 'htmlTag', 'subdomain'],
      // },
      interpolation: {
        escapeValue: false,
      },
      defaultNS: 'settings',
      backend: {
        crossDomain: false,
        allowMultiLoading: false,
        reloadInterval: false,
        loadPath: `/translations/{{ns}}.{{lng}}.json`,
        addPath: `/translations/{{ns}}.{{lng}}.json`,
      },
      react: {
        // @ts-expect-error i18n next typing error
        wait: true,
      },
    })
    .catch((error: Error) => {
      log(error);
    });
export const getInstance = () => {
  // TODO: lazy load file
  return i18n;
};
