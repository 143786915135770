import { useCallback, useEffect, useRef } from 'react';

export type UseTimeoutFnReturn = [
  () => boolean | undefined,
  () => void,
  () => void,
];

export default function useTimeoutFn(
  // eslint-disable-next-line @typescript-eslint/ban-types
  fn: Function,
  // eslint-disable-next-line @typescript-eslint/no-inferrable-types
  ms: number = 0,
): UseTimeoutFnReturn {
  const ready = useRef<boolean | undefined>(false);
  const timeout = useRef<ReturnType<typeof setTimeout>>();
  const callback = useRef(fn);

  const isReady = useCallback(() => ready.current, []);

  const set = useCallback(() => {
    ready.current = false;
    timeout.current && clearTimeout(timeout.current);

    timeout.current = setTimeout(() => {
      ready.current = true;
      callback.current();
    }, ms);
  }, [ms]);

  const clear = useCallback(() => {
    ready.current = undefined;
    timeout.current && clearTimeout(timeout.current);
  }, []);

  // update ref when function changes
  useEffect(() => {
    callback.current = fn;
  }, [fn]);

  // set on mount, clear on unmount
  useEffect(() => {
    set();

    return clear;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ms]);

  return [isReady, clear, set];
}
