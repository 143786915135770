import { BoxLineIcon } from './box-line-icon';
import { ShareBoxIcon } from './share-box-icon';
import { ClipBoardIcon } from './clipboard-icon';
import { CloseIcon } from './close-icon';
import { CustomerServiceIcon } from './customer-service';
import {
  CartLineIcon,
  TruckLineIcon,
  HomeIcon,
  MoneyEuroBoxIcon,
  SearchIcon,
  TrashIcon,
  ArrowsAltIcon,
  EditAltIcon,
  InfoCircleIcon,
  PlusIcon,
  PrintIcon,
  UndoIcon,
  SunIcon,
  FourSeasonIcon,
  TagIcon,
  StarIcon,
  AlertIcon,
  TimerFlashIcon,
  ShoppingCartIcon,
  WarehouseIcon,
  UsersIcon,
  BuildingIcon,
  CogsIcon,
  HistoryIcon,
  CollapseIcon,
  EyeIcon,
  DownloadIcon,
  MapMarkerIcon,
  MotorbikeIcon,
  MinusIcon,
  LightBulbIcon,
  LeafLineIcon,
  PlugLineIcon,
  FlakeIcon,
  DashboardIcon,
  GlobeIcon,
  SpinnerIcon,
  CloseCircleIcon,
  MountainFlakeIcon,
  ArrowLeftSLineIcon,
  DeleteBinLineIcon,
  FootballFillIcon,
  TimeSpeedIcon,
  ErrorWarningLineIcon,
  ChatLineIcon,
  CheckMarkIcon,
  PencilIcon,
  GroupIcon,
  CheckBoxCircleIcon,
} from './icons';
import { PostIcon } from './post-icon';
import { ReloadIcon } from './reload-icon';
import { TeamIcon } from './team-icon';
import { UploadLineIcon } from './upload-line-icon';
import { WarningTriangleIcon } from './warning-triangle';
import { MarketingFillIcon } from './marketing-fill-icon';
import { SurveyFillIcon } from './survey-fill-icon';
import { SettingsIcon } from './settings-icon';
import { AccountCircleIcon } from './account-circle-icon';
import { OpenedBurgerIcon } from './burger-opened-icon';
import { ClosedBurgerIcon } from './burger-closed-icon';
import { DuplicateIcon } from './duplicate-icon';
import { InformationLineIcon } from './information-line-icon';
import { HourGlassIcon } from './hour-glass-icon';
import { AddLineIcon } from './add-line-icon';
import { ShowersLineIcon } from './showers-line-icon';
import { GasStationLineIcon } from './gas-station-line-icon';
import { VolumeDownLineIcon } from './volume-down-line-icon';
import { PencilLineIcon } from './pencil-line-icon';
import { ArrowLeftRightLineIcon } from './arrow-left-right-line-icon';
import { EyeOffIcon } from './eye-off';
import { QuoteIcon } from './quote';
import { ArrowDownIcon, ArrowRightIcon, ArrowUpIcon } from './arrows';

export const ICON_LOOKUP = {
  'car-line': CartLineIcon,
  'truck-line': TruckLineIcon,
  'motorbike': MotorbikeIcon,
  'home': HomeIcon,
  'money-euro-box': MoneyEuroBoxIcon,
  'search': SearchIcon,
  'trash': TrashIcon,
  'arrows-alt': ArrowsAltIcon,
  'edit': EditAltIcon,
  'info-circle': InfoCircleIcon,
  'plus': PlusIcon,
  'minus': MinusIcon,
  'print': PrintIcon,
  'undo': UndoIcon,
  'sun': SunIcon,
  'flake': FlakeIcon,
  'four-season': FourSeasonIcon,
  'tag': TagIcon,
  'star': StarIcon,
  'alert': AlertIcon,
  'timer-flash': TimerFlashIcon,
  'shopping-cart': ShoppingCartIcon,
  'warehouse': WarehouseIcon,
  'users': UsersIcon,
  'building': BuildingIcon,
  'cogs': CogsIcon,
  'history': HistoryIcon,
  'collapse': CollapseIcon,
  'eye': EyeIcon,
  'eye-off': EyeOffIcon,
  'download': DownloadIcon,
  'map-marker': MapMarkerIcon,
  'light-bulb': LightBulbIcon,
  'plug-line': PlugLineIcon,
  'leaf-line': LeafLineIcon,
  'dashboard': DashboardIcon,
  'globe': GlobeIcon,
  'spinner': SpinnerIcon,
  'close-circle': CloseCircleIcon,
  'mountain-flake': MountainFlakeIcon,
  'arrow-left-s-line': ArrowLeftSLineIcon,
  'delete-bin-line': DeleteBinLineIcon,
  'football-fill': FootballFillIcon,
  'time-speed': TimeSpeedIcon,
  'error-warning-line': ErrorWarningLineIcon,
  'chat-line': ChatLineIcon,
  'check-mark-line': CheckMarkIcon,
  'pencil': PencilIcon,
  'group': GroupIcon,
  'post': PostIcon,
  'clip-board': ClipBoardIcon,
  'team': TeamIcon,
  'close': CloseIcon,
  'warning-triangle': WarningTriangleIcon,
  'reload': ReloadIcon,
  'check-box-circle': CheckBoxCircleIcon,
  'box-line': BoxLineIcon,
  'upload-line': UploadLineIcon,
  'customer-service': CustomerServiceIcon,
  'share-box': ShareBoxIcon,
  'marketing-fill': MarketingFillIcon,
  'survey-fill': SurveyFillIcon,
  'settings': SettingsIcon,
  'account-circle': AccountCircleIcon,
  'opened-burger': OpenedBurgerIcon,
  'closed-burger': ClosedBurgerIcon,
  'duplicate': DuplicateIcon,
  'information-line': InformationLineIcon,
  'hour-glass-icon': HourGlassIcon,
  'add-line': AddLineIcon,
  'showers-line': ShowersLineIcon,
  'gas-station-line': GasStationLineIcon,
  'volume-down-line': VolumeDownLineIcon,
  'pencil-line': PencilLineIcon,
  'arrow-left-right-line': ArrowLeftRightLineIcon,
  'quote': QuoteIcon,
  'arrow-up': ArrowUpIcon,
  'arrow-down': ArrowDownIcon,
  'arrow-right': ArrowRightIcon,
} as const;
