import React from 'react';
import {
  Id,
  toast,
  ToastContainer,
  ToastContent,
  ToastOptions,
  TypeOptions,
} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './overrides.scss';
import { Icon } from '../icon';

// const CloseButton = ({ closeToast }) => (
//   <FontAwesomeIcon
//     icon="times"
//     className="my-2 fs-xxs"
//     style={{ opacity: 0.5 }}
//     onClick={closeToast}
//   />
// );
// React.ComponentProps<typeof ToastContainer> &
type Props = { children?: React.ReactNode };
function ToastProvider({ children }: Props) {
  return (
    <>
      {children}
      <ToastContainer
        //   closeButton={CloseButton}
        icon={false}
        position={toast.POSITION.BOTTOM_RIGHT}
      ></ToastContainer>
    </>
  );
}

type ToastrFn = <TData = unknown>(
  content: ToastContent<TData>,
  // eslint-disable-next-line @typescript-eslint/ban-types
  options?: Omit<ToastOptions<{}>, 'icon' | 'theme' | 'position'> | undefined,
) => Id;
export const notify: Record<TypeOptions, ToastrFn> = {
  default: (message, options) => toast.success(message, options),
  success: (message, options) =>
    toast.success(message, {
      ...options,
      icon: <Icon icon='check-box-circle' className='text-success-text' />,
      theme: 'colored',
    }),
  warning: (message, options) =>
    toast.warn(message, {
      ...options,
      icon: <Icon icon='alert' className='text-warning-text' />,
      theme: 'colored',
    }),
  error: (message, options) =>
    toast.error(message, {
      ...options,
      icon: <Icon icon='alert' className='text-error-text' />,
      theme: 'colored',
    }),
  info: (message, options) =>
    toast.info(message, {
      ...options,
      icon: <Icon icon='info-circle' className='text-info-text' />,
      theme: 'colored',
    }),
};

export default ToastProvider;
