import { z } from 'zod';
const isoDateRegExp = new RegExp(
  /(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))/,
);

function isISODate(str: string) {
  return isoDateRegExp.test(str);
}
export const isoDateSchema = z
  .string()
  .refine(isISODate, { message: 'Not a valid ISO string date' });

export const ISOCountryCodes = {
  FR: 'FR',
};

export const REQUIRED_MESSAGE =
  'app.common.validations.required_field' as const;
export const MAX_LENGTH_MESSAGE =
  'app.common.validations.string_max_length' as const;
export const EMAIL_MESSAGE = 'app.common.validations.email.invalid' as const;
export const PASSWORD_MESSAGE =
  'app.common.validations.password.mismatch' as const;

export const requiredString = z
  .string({ required_error: REQUIRED_MESSAGE })
  .nonempty(REQUIRED_MESSAGE);
export const requiredNumber = z.number({
  invalid_type_error: REQUIRED_MESSAGE,
  required_error: REQUIRED_MESSAGE,
});
export const optionalEmail = z.union([
  z.literal(''),
  z.string().email(EMAIL_MESSAGE),
]);
